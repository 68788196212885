// Styles
// ------
import styled, { css } from 'styled-components';
import { breakup, Section, H1, Em } from '@tackl';

// Exports
// ------
export const Jacket = styled(Section)(p => css`
    position: relative;
    ${p.theme.vh};
    overflow: hidden;
`);

export const Image = styled.div`
    position: fixed;
    z-index:  1;
    top: 0; left: 0; right: 0; bottom: 0;
    width: 100%;
    ${props => props.theme.vh};

    &:before {
        content: '';
        position: absolute;
        z-index: 2;
        top: 0; left: 0; right: 0; bottom: 0;
        background: ${props => props.theme.black};
        opacity: .36;
    }

    .gatsby-image-wrapper {
        transition: all 2s ${props => props.theme.bezzy};
        ${props => props.theme.vh};
    }
`;

export const Brand = styled.div`
    transition: all 1.4s ${props => props.theme.bezzy};
    position: relative;
    z-index: 6;
    pointer-events: none;
    padding-top: 12rem;

    font-family: ${props => props.theme.fancy};
    font-size: 18.2vw;
    text-indent: -1vw;
    color: ${props => props.theme.bc3};

    transform: translateX(${props => props.isVisible ? `0` : `50vw`});
`;

export const Title = styled(H1)`
    transition: clip-path 1.4s ${props => props.theme.bezzy};
    transition-delay: .6s;
    position: relative;
    pointer-events: none;
    z-index: 6;
    transform: translateY(-3rem);

    clip-path: inset(${(props) => props.isVisible ? `0% 0% 0% 0%` : `50% 0% 50% 0%`});

    display: block;
    width: 100%;
    padding: 0 2.4rem;

    color: ${props => props.theme.bc1};

    ${breakup.medium`
        padding: 0 3.6rem;
        transform: translateY(-6rem);
    `}

    ${breakup.large`
        width: 75%;
        padding: 0 6rem;
        transform: translateY(-10rem);
    `}

    ${breakup.huge`
        width: 60%;
    `}
`;

export const Description = styled(Em)(p => css`
    position: absolute;
    z-index: 6;
    bottom: 2.4rem; left: 2.4rem;
    pointer-events: none;

    width: 50vw;

    transition: clip-path 2s ${props => props.theme.bezzy};
    transition-delay: .8s;
    clip-path: inset(${(props) => props.isVisible ? `0% 0% 0% 0%` : `50% 0% 50% 0%`});

    font-family: ${p.theme.heading};
    font-size: 1.4rem;

    ${breakup.medium`
        width: 30vw;
        font-size: 1.6rem;
        bottom: 3.6rem; left: 3.6rem;
    `}

    ${breakup.large`
        bottom: 4rem; left: 6rem;
    `}

    ${breakup.uber`
        width: 20vw;
    `}
`);

export const Flickr = styled.a((p) => css`
    position: absolute;
    z-index: 6;
    bottom: 2.4rem; right: 2.4rem;

    ${breakup.medium` bottom: 3.6rem; right: 3.6rem; `}
    ${breakup.medium` bottom: 4rem; right: 6rem; `}

    display: flex;
    align-items: center;

    transition: clip-path 1.2s ${props => props.theme.bezzy};
    transition-delay: .8s;
    clip-path: inset(${(props) => props.isVisible ? `0% 0% 0% 0%` : `50% 0% 50% 0%`});

    svg {
        width: 1.6rem;
        height: 1.6rem;
        margin-right: 1.2rem;
    }

    span {
        position: relative;
        transition: all .4s ${p.theme.bezzy};

        font-family: ${p.theme.heading};
        text-transform: uppercase;
        letter-spacing: 1px;
        font-weight: 500;
        font-size: 1.4rem;
        color: ${p.theme.bc1};

        &:before {
            transition: all .4s ${p.theme.bezzy};
            content: '';
            position: absolute;
            bottom: -1px;
            width: 0;
            height: 1px;
            background: ${p.theme.bc1};
        }
    }

    &:hover {
        span {
            filter: brightness(85%);

            &:before { width: 100%; }
        }
    }
`);