// Imports
// ------
import React, { useState, useEffect, Suspense, lazy } from 'react';
import Icon from '@icons';
import ClickNHold from 'react-click-n-hold';
import { GatsbyImage } from 'gatsby-plugin-image';
import { loader, pagetrans, galleryCursor, menu } from '@states';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { useTransitionStore } from 'gatsby-plugin-transitions';
import { action } from 'mobx';
import { observer } from 'mobx-react-lite';

// Styles
// ------
import { Jacket, Image, Brand, Title, Description, Flickr } from './styles';

// Component
// ------
const Canvas = lazy(() => import('./Canvas'));

const HomeHero = ({ data }) => {
    // NOTE • Breakpoints
	const bp = useBreakpoint();

    // NOTE • Dispatch
    const setTrue = action(() => {
        pagetrans.isDefault = false;
        menu.galleries = true;
    });

    // NOTE • States
    const [isMounted, setIsMounted] = useState(false);
    const [holding, setHolding] = useState(false);
    const [finished, setFinished] = useState(false);

    // NOTE • Mount the webgl
    useEffect(() => {
        setIsMounted(true);
    }, []);

    // NOTE • Transition Store
    const [, dispatch] = useTransitionStore();

    // NOTE • Click n Hold functions
    const start = (e) => {
        setHolding(true);
    }

    const end = (e, enough) => {
        if(enough) {
            setHolding(true);
            setFinished(true);
        } else {
            setHolding(false);
            setFinished(false);
        }
    }

    const clickHolding = (e) => {
        // console.log(e)
        galleryCursor.needsRelease = true;
    }

    useEffect(() => {
        const slug = "/galleries";

        if(finished) {
            // Return the new transition
            dispatch({
                type: "NAVIGATE",
                to: slug,
                leave: {
                    opacity: 0,
                    config: {
                        duration: 1000,
                        clamp: true,
                    },
                    onStart: () => {
                        setTrue();
                    }
                },
            });
        }
    }, [finished, dispatch]);

    return (
        <Jacket>
            <div key="galleryPage">
                {bp.large ? (
                    <>
                        <ClickNHold
                            time={2} // Time to keep pressing. Default is 2
                            onStart={start} // Start callback
                            onClickNHold={clickHolding} //Timeout callback
                            onEnd={end}
                            className="hold holder"
                        >
                            {isMounted && (
                                <Suspense fallback={<GatsbyImage image={data.featuredImage.data} alt={data.featuredImage.alt} />}>
                                    <Canvas image={data.featuredImage.url} />
                                </Suspense>
                            )}
                        </ClickNHold>
                    </>
                ) : (
                    <Image>
                        <GatsbyImage image={data.featuredImage.data} alt={data.featuredImage.alt} />
                    </Image>
                )}
            </div>

            <Brand isVisible={loader.isComplete && pagetrans.isDefault}>Photoscoper</Brand>

            <Title isVisible={loader.isComplete && pagetrans.isDefault}>{data.title}</Title>

            <Description isVisible={loader.isComplete && pagetrans.isDefault}>{data.subtext}</Description>

            <Flickr className="ch" href={data.flickrLink} target="_blank" rel="noopener nofollow" isVisible={loader.isComplete && pagetrans.isDefault}>
                <Icon type="flickr" />
                <span>{bp.large && `Follow on `}Flickr</span>
            </Flickr>
        </Jacket>
    );
}

export default observer(HomeHero);