// Imports
// ------
import React from 'react';
import Seo from '@parts/Seo';
import SmoothScroll from '@parts/SmoothScroll';
import Hero from '@parts/Home/Hero';
import { graphql } from 'gatsby';

// Page
// ------
const IndexPage = ({ data }) => (
	<>
		<Seo meta={data.home.seoMeta} />
		<SmoothScroll>
			<Hero data={data.home} />
		</SmoothScroll>
	</>
);

export default IndexPage;

// GraphQL
// ------
export const query = graphql`
    query HomeQuery {
		home: datoCmsHomepage {
			title
			subtext
			flickrLink
			featuredImage {
				alt
				url

				data: gatsbyImageData(
					width: 2000
					placeholder: BLURRED
					layout: FULL_WIDTH
				)
			}
			
			seoMeta {
				title
				image {
					url
				}
				desc: description
				card: twitterCard
			}
		}
	}
`;